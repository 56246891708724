import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function Navbar() {
  const paths = [
    { text: "WORK", path: "/" },
    { text: "ABOUT", path: "/about" },
  ];

  const [offset, setOffset] = useState(0);
  useEffect(() => {
    window.onscroll = () => {
      setOffset(window.scrollY);
    };
  }, []);

  const location = useLocation();
  const navigate = useNavigate();

  let classes = "";
  if (offset > 10) {
    classes += " scrolled";
  }

  return (
    <nav className={classes}>
      {paths.map((path) => {
        let classes = "item";
        if (location.pathname === path.path) {
          classes += " selected";
        }

        return (
          <div className={classes} onClick={() => navigate(path.path)}>
            {path.text}
          </div>
        );
      })}
    </nav>
  );
}

export default Navbar;
