interface BoldProps {
  children: React.ReactNode;
}

function Bold(props: BoldProps) {
  let { children } = props;
  return <span className="bold">{children}</span>;
}

export default Bold;
