interface HighlightProps {
  children: React.ReactNode;
}

function Highlight(props: HighlightProps) {
  let { children } = props;

  return <span className="highlight">{children}</span>;
}

export default Highlight;
