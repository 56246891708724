import { motion } from "framer-motion";

interface HeaderProps {
  children: React.ReactNode;
}

function Header(props: HeaderProps) {
  let { children } = props;
  return (
    <motion.h1
      initial={{ y: 100 }}
      whileInView={{ y: 0 }}
      transition={{ duration: 0.4 }}
      viewport={{ once: true }}
    >
      {children}
    </motion.h1>
  );
}

export default Header;
