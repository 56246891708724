import "../styles/Project.css";

import Hero from "../components/project/Hero";
import Navbar from "../components/Navbar";
import SideTitle from "../components/project/SideTitle";
import Card from "../components/project/Card";
import Gallery from "../components/project/Gallery";
import OverrideBoundary from "../components/project/OverrideBoundary";
import Subpages from "../components/project/Subpages";
import Footer from "../components/project/Footer";

import image from "../images/Marketplace.jpg";
import image2 from "../images/Glimpse-iphone.jpg";
import userflows from "../images/mp-userflows.svg";

import uresearch1 from "../images/uresearch1.png";
import uresearch2 from "../images/uresearch2.png";

import workshop1 from "../images/workshop1.png";
import workshop2 from "../images/workshop2.png";

import breadboard from "../images/breadboard.png";
import ideation1 from "../images/ideation1.png";
import ideation2 from "../images/ideation2.png";
import responsiveness from "../images/responsiveness.png";
import documentation from "../images/documentation.png";

import Highlight from "../components/Highlight";
import Bold from "../components/Bold";
import List from "../components/project/List";
import ListText from "../components/project/ListText";
import Container from "../components/project/Container";
import Content from "../components/project/Content";
import Text from "../components/project/Text";
import Image from "../components/project/Image";
import Header from "../components/project/Header";
import Subpage from "../components/project/Subpage";
import CardSection from "../components/project/CardSection";
import CardHeader from "../components/project/CardHeader";
import CardText from "../components/project/CardText";
import { useEffect } from "react";

function Marketplace() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navbar />
      <Hero
        title="Marketplace"
        description="A centralized hub to dicover, access, and integrate a diverse range of clinical data resources"
        image={image}
      />
      <Content>
        <SideTitle title="Project Context">
          <Card>
            <CardSection>
              <CardHeader>ROLE</CardHeader>
              <CardText>Product Designer</CardText>
            </CardSection>
            <CardSection>
              <CardHeader>METHODS & TOOLS</CardHeader>
              <CardText>Figma, Illustrator</CardText>
            </CardSection>
            <CardSection>
              <CardHeader>DATE</CardHeader>
              <CardText>Jul 2022 - Jan 2022</CardText>
            </CardSection>
            <CardSection>
              <CardHeader>COLLABORATION</CardHeader>
              <CardText>POs, QAs, BAs</CardText>
              <CardText>UX Lead, Developers</CardText>
            </CardSection>
          </Card>
          <Text>
            As the lead product designer for the Marketplace project, the focus
            was on the initation of the project from its conceptual stage.
          </Text>
        </SideTitle>
        <SideTitle title="Research">
          <Header>Understanding Marketplace</Header>
          <Text>
            The project began with the development of user flows to grasp the
            project's scope and identify its diverse user base. The objective
            was to comprehend various aspects of the application and gain some
            insight into the requirements of each user.
          </Text>

          <OverrideBoundary backgroundColor="#f4f4fc">
            <Image src={userflows} alt="Project" />
          </OverrideBoundary>

          <Text>
            The next phase involved close collaboration with our product owners
            to gain a better understanding of user needs. Together, we
            formulated research questions and categorized users into buyers and
            sellers to help tailor our design solutions.
          </Text>

          <Gallery images={[uresearch1, uresearch2]} />

          <Text>
            For buyers, our focus was on doctors from private clinics, allowing
            us to gather firsthand insights into their preferences.
            Additionally, we interviewed representatives from B.Well, a company
            specializing in innovative clinical data sorting applications, to
            understand the perspectives of potential sellers in the healthcare
            ecosystem.
          </Text>

          <List title="Key insights from user interviews">
            <ListText>
              Respondents expressed a desire for quick overviews of an app, with
              more detailed descriptions available when explored further
            </ListText>
            <ListText>
              Significance of categorization, particularly for sellers who have
              multiple applications
            </ListText>
            <ListText>
              Importance of a robust search function, seeking ease and
              efficiency in finding relevent applications within the Marketplace
            </ListText>
          </List>

          <Text>
            Teaming up with my UX lead, we organized a brand strategy workshop
            to get various opinons and perspectives. Participants, ranging from
            marketing professionals and product owners to developers and
            stakeholders, actively contributed to shaping a collaborative
            environment dedicated to understanding our brand objectives.
          </Text>

          <Image src={workshop1} alt="Project" />
          <Image src={workshop2} alt="Project" />

          <List title="Key insights from workshop">
            <ListText>
              Create a banner inspired by references from the workshop and
              emphasize a visually appealing design that aligns with the brand's
              essence.
            </ListText>
            <ListText>
              Adopt a minimalist appearance to convey professionalism and
              trustworthiness through simplicity in design elements.
            </ListText>
            <ListText>
              Prioritize a seamless and responsive user experience in product
              design, aiming for features that engage users and enhance their
              interaction and satisfaction across various platforms.
            </ListText>
          </List>
        </SideTitle>

        <SideTitle title="Design Process">
          <Text>
            Collaborated with the UX lead to breakdown key insights from user
            interviews and the strategy workshop, leveraging them to breadboard
            the design of Marketplace.
          </Text>
          <Image src={breadboard} alt="Project" />
        </SideTitle>

        <Subpages titles={["Iterations", "Responsiveness", "Documentation"]}>
          <Subpage>
            <OverrideBoundary width={50}>
              <Gallery
                images={[ideation1, ideation2]}
                titles={["BEFORE", "AFTER"]}
              />
            </OverrideBoundary>
          </Subpage>

          <Subpage>
            <Image src={responsiveness} />
          </Subpage>

          <Subpage>
            <Image src={documentation} />
          </Subpage>
        </Subpages>
      </Content>
      <Footer
        image={image2}
        title="Glimpse Social"
        subtext="Mobile Application, Branding"
        path="/projects/glimpsesocial"
      />
    </>
  );
}

export default Marketplace;
