import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

import "../styles/About.css";
import profilePortrait from "../images/aboutpic.jpg";
import Link from "../components/Link";

interface AboutMainProps {
  children?: React.ReactNode;
  title?: string;
  photo: string;
}

function AboutMain(props: AboutMainProps) {
  let { photo, children, title } = props;

  return (
    <div className="about">
      <h1>{title}</h1>
      <div className="info">
        <div className="content">
          {children}
          <div className="buttons">
            <div className="button">Resume</div>
          </div>
        </div>
        <img src={photo} alt="Portrait" />
      </div>
    </div>
  );
}

function About() {
  return (
    <>
      <Navbar />
      <AboutMain photo={profilePortrait} title={"Hey There!"}>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea <Link action={() => {}}>commodo consequat</Link>.
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat.
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat.
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat.
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </p>
      </AboutMain>
      <Footer />
    </>
  );
}

export default About;
