import { motion } from "framer-motion";

interface OverrideBoundaryProps {
  children: React.ReactNode;
  alt?: boolean;
  backgroundColor?: string;
  width?: number;
}

function OverrideBoundary(props: OverrideBoundaryProps) {
  let { children, alt = false, backgroundColor, width = 100 } = props;
  let classes = "overrideBoundary";
  if (alt) {
    classes += " alt";
  }
  return (
    <motion.div
      initial={{ y: 100 }}
      whileInView={{ y: 0 }}
      transition={{ duration: 0.4 }}
      className={classes}
      viewport={{ once: true }}
      style={
        backgroundColor
          ? { backgroundColor: backgroundColor }
          : { width: width + "vw" }
      }
    >
      {children}
    </motion.div>
  );
}

export default OverrideBoundary;
