import "../styles/Project.css";

import Hero from "../components/project/Hero";
import Navbar from "../components/Navbar";
import SideTitle from "../components/project/SideTitle";
import Card from "../components/project/Card";
import Gallery from "../components/project/Gallery";
import OverrideBoundary from "../components/project/OverrideBoundary";
import Subpages from "../components/project/Subpages";
import Footer from "../components/project/Footer";

/* This is for design process*/
import image from "../images/Glimpse-iphone.jpg";
import image2 from "../images/Marketplace.jpg";
import userinterviews from "../images/UserInterviews.png";
import personas from "../images/Personas.png";
import sitemap from "../images/Sitemap.png";
import userflows from "../images/Userflows.png";

/* This is for design process gallery*/
import ideations from "../images/ideations.jpg";
import oldlogin from "../images/oldlogin-page.svg";
import newlogin from "../images/newlogin-page.svg";
import oldacc from "../images/oldacc-page.svg";
import newacc from "../images/newacc-page.svg";
import oldloading from "../images/oldloading-page.svg";
import oldmap from "../images/oldmap-page.svg";

/* sbadow hierachy */
import droppin from "../images/shadow-droppin.svg";
import confirmpin from "../images/shadow-confirm.svg";

/* bold & whitespace */
import map from "../images/map.svg";
import businesspage from "../images/businesspage.svg";

/* motion animation */
import glimpseupload from "../images/glimpse-upload.gif";
import glimpsenocontent from "../images/glimpse-nocontent.gif";

/* primary & accent */
import colorpalette from "../images/colorpalette.svg";
import businessicons from "../images/businessicons.png";

import Highlight from "../components/Highlight";
import Bold from "../components/Bold";
import Container from "../components/project/Container";
import Content from "../components/project/Content";
import Text from "../components/project/Text";
import CardSection from "../components/project/CardSection";
import CardHeader from "../components/project/CardHeader";
import CardText from "../components/project/CardText";
import Image from "../components/project/Image";
import List from "../components/project/List";
import ListText from "../components/project/ListText";
import Header from "../components/project/Header";
import Subpage from "../components/project/Subpage";
import SubHeader from "../components/project/SubHeader";
import { useEffect } from "react";

function Glimpsesocial() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navbar />
      <Hero
        title="Glimpse Social"
        description="Designed to give you the most immersive experience and updates on events"
        image={image}
      />
      <Content>
        <SideTitle title="Problem">
          <Text>
            The COVID-19 pandemic has affected how customers shop and view
            entertainment, negatively impacting minority-owned, underserved, and
            independent businesses. These businesses struggle to gain visibility
            and increase foot traffic due to the lack of budgeting and media.{" "}
          </Text>
          <Card>
            <CardSection>
              <CardHeader>ROLE</CardHeader>
              <CardText>Lead Product Designer</CardText>
            </CardSection>
            <CardSection>
              <CardHeader>METHODS & TOOLS</CardHeader>
              <CardText>XD, Illustrator, After Effects</CardText>
            </CardSection>
            <CardSection>
              <CardHeader>DATE</CardHeader>
              <CardText>Sep 2019 - Mar 2022</CardText>
            </CardSection>
            <CardSection>
              <CardHeader>COLLABORATION</CardHeader>
              <CardText>Developers, Researcher, CEO</CardText>
            </CardSection>
          </Card>
        </SideTitle>

        <SideTitle title="Research">
          <Text>
            <Bold>User interviews</Bold> conducted from a survey to get an
            insight into the preferences of NYC customers and business owners on
            social media applications.
          </Text>
          <Image src={userinterviews} alt="Project" />
          <Text>
            <Bold>Personas</Bold> for customer and a business owner created from
            user inteviews and online research. Used for prototyping and
            designing mock ups.
          </Text>
          <Image src={personas} alt="Project" />
          <List title="Key insights from research analysis">
            <ListText>Seamless two-way communication for both parties</ListText>
            <ListText>
              Integrate principles of material design for visual appeal,ensuring
              high-quality experience
            </ListText>
            <ListText>
              Prioritize convenience and simplicity of features to ensure
              user-friendliness
            </ListText>
          </List>
        </SideTitle>

        <SideTitle title="Proposal">
          <Text>
            How can we design an experience to bring businesses to the same
            level of exposure as big competitors? What may entice customers to
            view local establishments and drive traffic to these businesses?{" "}
          </Text>
        </SideTitle>

        <SideTitle title="Design Process">
          <Text>
            Glimpse Social offers unlimited free advertising within the app and
            allows these businesses to push their updates to traditional social
            channels. For the first time, businesses of all types can
            collectively work together to raise their profile in the city and
            with potential customers, driving foot traffic and customers when
            they need them. <br />
            <br />
            <Bold>Sitemap</Bold> to determine what features and pages would be
            valuable for users based on the research and ideation.{" "}
          </Text>
          <Image src={sitemap} alt="Project" />
          <Text>
            <Bold>User flows</Bold> designed to make sure the usability and
            available features were accessible and straightforward.{" "}
          </Text>
          <Image src={userflows} alt="Project" />
        </SideTitle>

        <Subpages titles={["Ideation", "Wireframes", "A/B Testing"]}>
          <Subpage>
            <Image src={ideations} />
          </Subpage>

          <Subpage>
            <Gallery images={[oldlogin, oldacc, oldloading, oldmap]} />
          </Subpage>

          <Subpage>
            <Gallery
              images={[oldlogin, newlogin, oldacc, newacc]}
              titles={["BEFORE", "AFTER", "BEFORE", "AFTER"]}
            />
          </Subpage>
        </Subpages>

        <SideTitle title="Design Decisions">
          <SubHeader>Shadow Hierarchy</SubHeader>
          <Text>
            Using shadows to <Highlight>convey hierarchy</Highlight> to impact
            the <Highlight>prioritization of elements</Highlight> for users and{" "}
            <Highlight>solve usability issues.</Highlight>
          </Text>
          <Gallery images={[droppin, confirmpin]} />

          <SubHeader>Bold Colors and Whitespace</SubHeader>
          <Text>
            Utilizing bold colors to <Highlight>create</Highlight> meaning and{" "}
            <Highlight>focus</Highlight> for user experience. Leveraged
            whitespace through create <Highlight>typography</Highlight> and{" "}
            optimized <Highlight>text layout</Highlight> to{" "}
            <Highlight>emphasize key elements.</Highlight>
          </Text>
          <Gallery images={[map, businesspage]} />

          <SubHeader>Motion Animations</SubHeader>
          <Text>
            Leveraged motion animation to{" "}
            <Highlight>guide user interactions</Highlight> and{" "}
            <Highlight>communicate functionality</Highlight>, enhancing the user
            experience during processes like loading into the map interface.
          </Text>
          <Gallery images={[glimpseupload, glimpsenocontent]} />

          <SubHeader>Primary and Accent Colors</SubHeader>
          <Text>
            Optimizing <Highlight>visual clarity</Highlight> and{" "}
            <Highlight>design aesthetics</Highlight> by applying{" "}
            <Highlight>concise color schemes</Highlight> for backgrounds,
            fields, fonts and key interface elements.
          </Text>
          <Image src={businessicons} alt="Project" />
          <Image src={colorpalette} alt="Project" />
        </SideTitle>
      </Content>
      <Footer
        image={image2}
        title="Marketplace"
        subtext="Desktop Application, Product Framework"
        path="/projects/marketplace"
      />
    </>
  );
}

export default Glimpsesocial;
