import { motion } from "framer-motion";
import { useState } from "react";

interface SubpagesProp {
  children: JSX.Element[];
  titles?: string[];
}

function Subpages(props: SubpagesProp) {
  const [pageIndex, setPageIndex] = useState(0);

  let { children, titles } = props;

  return (
    <motion.div
      initial={{ y: 100 }}
      whileInView={{ y: 0 }}
      transition={{ duration: 0.4 }}
      className="subpages"
      viewport={{ once: true }}
    >
      <div className="nav">
        {children.map((child, index) => {
          let classes = "button";
          if (index === 0) {
            classes += " first";
          }
          if (index === children.length - 1) {
            classes += " last";
          }
          if (index === pageIndex) {
            classes += " selected";
          }
          return (
            <div
              className={classes}
              onClick={() => {
                setPageIndex(index);
              }}
            >
              {titles && titles[index] ? titles[index] : index}
            </div>
          );
        })}
      </div>
      {children.map((child, index) => {
        if (index === pageIndex) {
          return child;
        } else {
          return "";
        }
      })}
    </motion.div>
  );
}

export default Subpages;
