import { motion } from "framer-motion";

interface HeroProps {
  title: string;
  description: string;
  image: string;
}

function Hero(props: HeroProps) {
  let { title, description, image } = props;
  return (
    <>
      <div className="projectHero">
        <motion.div
          className="details gap-80"
          initial={{ x: -25 }}
          animate={{
            x: 0,
          }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
        >
          <h1 className="title">{title}</h1>
          <h1 className="description">{description}</h1>
        </motion.div>
        <img src={image} alt={title + " Thumbnail"} />
      </div>
    </>
  );
}

export default Hero;
