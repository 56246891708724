import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import Typewriter from "typewriter-effect";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

import Glimpse from "../images/Glimpse-iphone.jpg";
import defaultImage from "../images/Marketplace.jpg";

import "../styles/Work.css";
import Link from "../components/Link";

interface HeroProps {
  texts?: string[];
}

interface TagProps {
  text: string;
  action: () => void;
}

interface ProjectProps {
  image: string;
  title: string;
  path: string;
  tags?: (TagProps | string)[];
}

function Hero(props: HeroProps) {
  let { texts } = props;
  return (
    <motion.div
      className="workHero"
      initial={{ x: -100 }}
      animate={{
        x: 0,
      }}
      transition={{ duration: 1 }}
      viewport={{ once: true }}
    >
      <h1 className="subtext">
        👋 Hello, I'm <Link action={() => {}}>Johnny Hoang Nguyen.</Link>
      </h1>
      <div className="header">
        <h1>I create better experiences for </h1>
        <Typewriter
          options={{
            strings: texts,
            autoStart: true,
            loop: true,
            deleteSpeed: 40,
            delay: 100,
            wrapperClassName: "typewriterText",
            cursorClassName: "typewriterText",
          }}
        />
      </div>
    </motion.div>
  );
}

function Project(props: ProjectProps) {
  let { image, title, tags, path } = props;
  let navigate = useNavigate();

  if (!tags) {
    tags = [];
  }

  return (
    <div className="project pressable" onClick={() => navigate(path)}>
      <img src={image} className="thumbnail" alt={title + " Thumbnail"} />
      <div className="details">
        <h1 className="title">{title}</h1>
        <div className="tags">
          {tags.map((tag) => {
            let classes = "tag";
            let text = typeof tag === "object" ? tag.text : tag;

            if (typeof tag === "object") {
              classes += " pressable";
            }

            return <div className={classes}>{text}</div>;
          })}
        </div>
      </div>
    </div>
  );
}

function Work() {
  let heroTexts = ["users", "people", "lemonade"];

  return (
    <>
      <Navbar />
      <Hero texts={heroTexts} />
      <div className="projects">
        <Project
          image={defaultImage}
          title="Marketplace"
          path="/projects/marketplace"
          tags={["Framework", "Desktop App"]}
        />
        <Project
          image={Glimpse}
          title="Glimpse Social"
          path="/projects/glimpsesocial"
          tags={["Branding", "Mobile App"]}
        />

        {/* <Project
          image={defaultImage}
          title="Glimpse Social"
          path="/"
          tags={["Design", "Software", { text: "Twitter", action: () => {} }]}  <- button link
        /> */}
      </div>
      <Footer />
    </>
  );
}

export default Work;
