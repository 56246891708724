import { motion } from "framer-motion";

interface ImageProps {
  src: string;
  alt?: string;
}

function Image(props: ImageProps) {
  let { src, alt = "Project" } = props;
  return (
    <motion.img
      className="aloneImage"
      initial={{ y: 100 }}
      whileInView={{ y: 0 }}
      transition={{ duration: 0.4 }}
      viewport={{ once: true }}
      src={src}
      alt={alt}
    />
  );
}

export default Image;
