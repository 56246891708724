interface ContentProps {
  children: React.ReactNode;
}

function Content(props: ContentProps) {
  let { children } = props;
  return <div className="projectContent">{children}</div>;
}

export default Content;
